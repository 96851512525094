// Customizable Area Start
import React from "react";
import { Box, Dialog, Modal, Tooltip, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { closeIcon, searchIcon } from "../../../appointmentmanagement/src/assets";
import { IGroupPendingRequest } from "../../../../components/src/CommonTypes";
import { GreyClock, InvalidImg, RedCheck, RedClose } from "../assets";

interface Props {
    pendingRequesList: IGroupPendingRequest[];
    open: boolean;
    onAcceptRequest: (groupAccountId: number | string, status: string) => void;
    onRejectRequest: (groupAccountId: number | string, status: string) => void;
    onClose: () => void;
    onSearch: (value: string) => void;
    errorInAcceptingRejectingRequest: string;
}


const GroupPendingRequestsListModal: React.FC<Props> = (props: Props) => {
    const { pendingRequesList, onClose, open, onSearch, onAcceptRequest, onRejectRequest, errorInAcceptingRejectingRequest } = props;

    return (
        <Modal open={open} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <StyledGroupPendingRequestModal className="GroupPendingRequest_Styled">
                <Box className="header">
                    <p>Pending Request</p>
                    <img src={closeIcon} alt="close.svg" onClick={onClose} />
                </Box>
                <Box p={"1rem 1rem 0rem 1rem"}>
                    <Box className="search-container">
                        <img src={searchIcon} alt="search" />
                        <input placeholder="Search" data-test-id="group-pending-request-search" onChange={(event) => onSearch(event.target.value)} />
                    </Box>
                </Box>
                {errorInAcceptingRejectingRequest && <Box textAlign={"center"} p={"1rem 1rem 0rem 1rem"}>
                    <Typography component={"span"} className="accept-reject-error">
                        {errorInAcceptingRejectingRequest}
                    </Typography>
                </Box>}
                <Box className="body">
                    {
                        pendingRequesList?.length > 0 && (
                            <Box className="pending-requests">
                                {
                                    pendingRequesList.map((item) => {
                                        return (
                                            <Box className="pending-request-info" key={item?.id}>
                                                <Box className="group-icon">
                                                    {item?.attributes?.group_info?.image_url ? <img className="group-icon-img" data-test-id="group-icon-img" src={item.attributes.group_info.image_url} alt="" /> :
                                                        <img className="group-icon-img" src={InvalidImg} alt="" />}
                                                </Box>
                                                <Box width={"99%"} display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                                                    <Box display={"flex"} flexDirection={"column"}>
                                                        <Tooltip title={item.attributes.group_info.name} placement="top">
                                                            <Typography component={"span"} className="group-name ellipsis">
                                                                {item.attributes.group_info.name}
                                                            </Typography>
                                                        </Tooltip>
                                                        <Tooltip title={item.attributes.account_info.full_name} placement="top">
                                                            <Typography component={"span"} className="group-member-name ellipsis">
                                                                {item.attributes.account_info.full_name}
                                                            </Typography>
                                                        </Tooltip>
                                                        <Box display={"flex"} alignItems={"center"}>
                                                            <img src={GreyClock} alt="time" />
                                                            <Typography component={"span"} className="created-at">
                                                                {item.attributes.created_at}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="group-pending-request-action" display={"flex"}>
                                                        <img id="accept-request-icon" onClick={() => onAcceptRequest(item.id, "accepted")} src={RedCheck} alt="accept-request" />
                                                        <img id="reject-request-icon" onClick={() => onRejectRequest(item.id, "rejected")} src={RedClose} alt="reject-request" />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        )
                    }
                    {
                        pendingRequesList?.length === 0 && (
                            <Box className="no-pending-requests">No pending requests found</Box>
                        )
                    }
                </Box>
            </StyledGroupPendingRequestModal>
        </Modal>

    )
}

const StyledGroupPendingRequestModal = styled("div")({
    "&.GroupPendingRequest_Styled": {
        display: "flex",
        flexDirection: "column",
        width: "26.25rem",
        height: "34.3125rem",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& .accept-reject-error": {
            color: "red",
            fontSize: "12px",
            fontWeight: 500,
        },
        "& .search-container": {
            display: "flex",
            backgroundColor: "#00000026",
            gap: "0.5rem",
            borderRadius: "6.25rem",
            padding: "0.75rem 1rem",
            "& input": {
                outline: "none",
                border: "none",
                background: "transparent",
                width: "100%",
                color: "white"
            }
        },
        "& .header": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "relative",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            boxSizing: "border-box",
            userSelect: "none",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "1rem",
            "& .pending-requests": {
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                "& .pending-request-info": {
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.875rem",
                    color: "white",
                    gap: "0.5rem",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #2d4953",
                    "& .group-icon-img": {
                        width: "75px",
                        height: "75px",
                        borderRadius: "1rem",
                    },
                    "& .group-name": {
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "14px",
                        fontWeight: 400,
                        display: "block",
                        maxWidth: "140px",
                    },
                    "& .group-member-name": {
                        color: "rgba(255, 255, 255, 1)",
                        fontSize: "16px",
                        display: "block",
                        maxWidth: "140px",
                    },
                    "& .created-at": {
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                        fontWeight: 400,
                        marginLeft: "5px",
                    },
                    "& .group-pending-request-action": {
                        gap: "15px",
                        paddingTop: "3px",
                        "& img": {
                            cursor: "pointer",
                            width: "10px",
                            height: "10px",
                            "&:first-child": {
                                width: "15px",
                                height: "11px",
                            }
                        }
                    }
                },
                "& > .pending-request-info:last-child": {
                    borderBottom: "none"
                }
            },
            "& .no-pending-requests":{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                color: "white",
                fontWeight: "400",
                flex: 1
            }
        },
        [`@media screen and (max-width: 600px)`]: {
            width: "auto",
            height: "70%"
        },
    }
})

export default GroupPendingRequestsListModal;
// Customizable Area End