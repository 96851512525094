// Customizable Area Start
import React from "react";
import CommentsListController, { Props } from "./CommentsListController.web";
import { Box, CircularProgress, IconButton, Menu, styled } from "@material-ui/core";
import { MoreIcon } from "../../activityfeed/src/assets";
// Customizable Area End

export default class CommentsList extends CommentsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CommentsListMenuOptionDropdown = () => {
    const { onDeleteComment } = this.props;
    const { 
        optionMenuAnchor,
        selectedCommentItem
      } = this.state;
    return (
        <CustomCommentListMenuOptions
            className="Custom_List_Menu_Comment"
            data-test-id="custom-list-menu-comment"
            open={Boolean(optionMenuAnchor)}
            onClose={this.closeCommentListOptionMenu}
            anchorEl={optionMenuAnchor}
            PaperProps={{
                style: {
                    transform: "translateY(0.5rem)"
                }
            }}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
        >
            <StyledCommentsListMenuOptionDropdown id="group-post-comment-options-menu" className="CommentsListMenuOptionDropdown_Web">
                <Box id="delete-group-post-comment-icon" className="item"
                    onClick={() => {
                        if (onDeleteComment) {
                            if(selectedCommentItem){
                             onDeleteComment(selectedCommentItem.id);
                            }
                            this.closeCommentListOptionMenu()
                        }
                    }}
                >
                    <svg id="delete-group-post-comment-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                    </svg>
                    Delete
                </Box>
            </StyledCommentsListMenuOptionDropdown>
        </CustomCommentListMenuOptions>
    )
}

  CommentsList = () => {
    const { 
      commentsList,
      isCommentsLoading,
      userId,
    } = this.props;
    const { optionMenuAnchor } = this.state;
    return (
        <StyledGroupPostComments className="Group_Post_Comments_Container">
            {isCommentsLoading &&
                <Box className="circular-progress-loader">
                    <CircularProgress />
                </Box>
            }
            <Box className="group-comments-body">
                <div id="group-post-comment-top-div"></div>
                {
                    commentsList && commentsList.length > 0 && (
                        <Box className="group-comments-container">
                            {
                                commentsList.map((comment) => {
                                    return (
                                        <Box key={comment.id} className="group-post-comment">
                                            <Box className="group-post-comment-details">
                                                <Box className="name">
                                                    <Box className="avatar-text">
                                                        {
                                                            comment.attributes.account_info.full_name[0]
                                                        }
                                                    </Box>
                                                    <p>
                                                        {
                                                            comment.attributes.account_info.full_name
                                                        }
                                                        <span>{comment.attributes.raw_created_at}</span>
                                                    </p>

                                                </Box>
                                                {
                                                        userId === comment.attributes.account_id && (
                                                            <div>
                                                                <Box id="delete-group-post-comment-option-icon" style={{
                                                                    cursor: "pointer"
                                                                }} onClick={event => {
                                                                    this.openCommentListOptionMenu(event);
                                                                    this.setState({ selectedCommentItem: comment })
                                                                }}>
                                                                    <img src={MoreIcon} />
                                                                </Box>
                                                                {optionMenuAnchor && this.CommentsListMenuOptionDropdown()}
                                                            </div>
                                                        )
                                                }
                                            </Box>
                                            <Box className="group-post-comment-text">{comment.attributes.comment}</Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    )
                }
                {
                    (!isCommentsLoading && commentsList?.length) === 0 && (
                        <Box className="no-comments">No comments found</Box>
                    )
                }
            </Box>
        </StyledGroupPostComments>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
        <>
            {this.CommentsList()}
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledGroupPostComments = styled("div")({
    "&.Group_Post_Comments_Container": {
        minHeight: "4rem",
        maxHeight: "26.8125rem",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .group-comments-body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "3px",
            "& .group-comments-container":{
                display: "flex",
                flexDirection: "column",
                "& .group-post-comment":{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "1px solid #2d4953",
                    padding: "0.5rem 0rem",
                    "& .group-post-comment-details":{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "& .name":{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            "& > p": {
                                fontSize: "0.75rem",
                                fontWeight: 400,
                                color: "rgba(255, 255, 255, 1)",
                                "& > span": {
                                    fontSize: "0.5rem",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    marginLeft: "0.5rem"
                                }
                            }
                        }
                    },
                    "& .group-post-comment-text": {
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)",
                        marginLeft: "2.5rem"
                    },
                    "&:last-child":{
                        borderBottom: "none"
                    }
                }
            },
            "& .no-comments":{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                color: "white",
                fontWeight: "400",
                flex: 1
            }
        },
        "& .footer": {
            padding: "1rem",
            borderTop: "1px solid #2d4953",
            display: "flex"
        },
        "& .circular-progress-loader": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiCircularProgress-colorPrimary":{
                color: "#F75367 !important"
            }
        }
    }
})

const StyledCommentsListMenuOptionDropdown = styled("div")({
    "&.CommentsListMenuOptionDropdown_Web": {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #19B5B945",
        width: "9.8125rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "0.5rem",
        padding: "0.25rem 0rem",
        "& .item": {
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.25rem 0.7rem",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

const CustomCommentListMenuOptions = styled(Menu)({
    "&.Custom_List_Menu_Comment": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})
// Customizable Area End
