import React from "react";

// Customizable Area Start
import {
  Grid,
  Typography,
  Menu
} from "@material-ui/core";
import { logoImage, leftAreaImage } from "./assets";
import { styled } from "@material-ui/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { StyleSheet } from "react-native";
export const configJSON = require("./config.js");

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

import { Timer } from "../../../components/src";
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  renderCountryCodeList = ()=>{
    const { searchStringForCountry } = this.state;
    return (
     <CustomMenu
      id="country-menu-list"
      anchorEl={this.state.countryCodeMenuRef}
      open={Boolean(this.state.countryCodeMenuRef)}
      onClose={this.onCloseCountryDropdown}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      getContentAnchorEl={null}
    >
       <div style={{width: '10rem',height:'15rem',background:'linear-gradient(180deg, #23404B 0%, #19282F 100%)',overflow:'auto'}}>
          <StyledSearchCountryInput >
            <input value={searchStringForCountry} placeholder="Search Country" onChange={this.onSearchCountry} />
          </StyledSearchCountryInput>
          {this.state.phoneNumberGetData.filter(phoneNumberData=> phoneNumberData.phone_code.includes(searchStringForCountry) || phoneNumberData.name.toLowerCase().includes(searchStringForCountry.toLowerCase())).map(
            (data: {
              flag: string;
              name: string;
              phone_code: string;
              length: Array<number>;
            },index:number) => (
              <div
                data-test-id="countrySelectId"
                key={index}
                onClick={() =>
                  this.handleCountrySelect(data, data.phone_code)
                }
                style={{padding:'0.5rem',cursor:'pointer'}}
              >
                <Typography style={{display:"flex", alignItems:"center",fontSize:'0.75rem',color:'#fff',gap:'0.5rem'}}>
                  <img src={data.flag} style={{width:'1rem'}} /> (
                  {data.phone_code}) {data.name}
                </Typography>
              </div>
            )
          )}
        </div>
     </CustomMenu>
    )
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <SignInView className="SignIn_SignInView">
      <Grid container>
        <Grid className="left" item sm={12} xs={12} md={6} lg={6} >
          <div style={{width:'100%',height:'100vh',overflow:'hidden', padding:"0.5rem", boxSizing:"border-box"}}>
            <img src={leftAreaImage} className="loginLeftImg"/>
          </div>
        </Grid>
          <Grid className="right" item sm={12} xs={12} md={6} lg={6}>
            <div className="loginRightArea">
              <div className="childContainer">
                <div className="logoImgBox">
                  <img src={logoImage} />
                </div>
                <div className="loginInputBoxContainer">
                  <label data-test-id="phoneLabel" className="inputLabel">
                    {
                      this.state.phoneNumberData || this.state.inputLabel ? configJSON.phoneMandateField : ""
                    }
                  </label>
                  <div className="inputBox" style={{ position: "relative" }}>
                    <CustomDropDown>
                      <DropDownHeader data-test-id="toggleCountryId"
                        onClick={this.toggleDropdown}
                        style={{display:'flex',justifyContent:'center',alignItems:"center"}}
                      >
                        {this.state.selectedCountry ? (
                          <SelectedCountry>
                            <Typography>  {this.state.selectedCountry.phone_code}</Typography>
                          </SelectedCountry>
                        ) : (
                          <Typography>{this.state.isCountryData}</Typography>
                        )}
                        <span className="selectDropdown">{this.state.dropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                      </DropDownHeader>
                      {this.renderCountryCodeList()}
                    </CustomDropDown>
                    <input placeholder={configJSON.phoneNumberField} onFocus={this.handleFocus} data-test-id="input"
                      onBlur={this.handleBlur} className="inputFieldPhone" type="text" value={this.state.phoneNumberData} onChange={(event) => this.handleNumberInput(event.target.value)} 
                      onKeyDown={(event)=>{
                        if(event.key === "Enter" && this.state.phoneNumberData){
                          this.handleLogin()
                        }
                      }}
                      />
                    {
                      this.state.isTimerStart && (
                        <Timer
                          style={{
                            textAlign: "center",
                            marginTop: "0.5rem",
                            position: "absolute",
                            right: "0",
                            top: "-1.6rem"
                          }}
                          isTimerOn={this.state.isTimerStart}
                          onTimerEnd={this.onTimerEnd}
                        />
                      )
                    }
                  </div>
                  {this.state.errorPhoneNumber.length > 0 && <span id="phoneError" className="errorMessage">{this.state.errorPhoneNumber}</span>}
                  <div style={{ marginTop: "2rem" }} className="loginBtnContainer" onClick={this.handleLogin} data-test-id="loginBtn">
                    <button className="loginBtn" disabled={!this.state.phoneNumberData || this.state.isTimerStart} type="submit" >{configJSON.signInBtn}</button>
                  </div>

                  <div className="alreadyAccountTxt">
                    <span className="txtNormalPalette" data-testId={"signInHeadingTestID"}>
                      {configJSON.dontAccount} <span className="textNavigate" style={{cursor:"pointer"}} onClick={this.navigateToSignUp} data-test-id={"signUpBtnTestID"}>{configJSON.signUpBtn}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
      </Grid>
    </SignInView>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});

const SignInView = styled("div")({
  "&.SignIn_SignInView": {
    width: "100%",
    background: "#0F2631",
    "& .avatarImg": {
      width: "100%",
      height: "100%",
      borderRadius: "20px",
    },

    "& .errorMessage": {
      color: "#ff2f3f",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px"
    },

    "& .editAvatarBtn": {
      top: "-17%",
      minWidth: "0px",
      padding: "0px",
      float: "right",
      background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
      borderRadius: "100px",
      width: "24px",
      height: "24px",
    },

    "& .editIcon": {
      color: "white",
      fontSize: "20px",
      padding: "2px",
    },

    "& .loginRightArea": {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flexBasis: "50%",
    },

    "& .childContainer": {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },

    "& .dropDownMenu": {
      marginTop: "50px",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px"
    },

    "& .loginLeftImg": {
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
    },

    "& .avatarImgContainer": {
      width: "88px",
      height: "89.25px",
      borderRadius: "20px",
      backgroundColor: "grey",
      marginTop: "20px",
    },

    "& .inputBox": {
      display: "flex",
      paddingTop: "10px",
    },

    "& .inputField": {
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      padding: "10px 16px 10px 16px",
      borderRadius: "100px",
      border: "1px solid rgba(28, 150, 154, 0.11)",
      width: "100%",
      color: "rgba(255, 255, 255, 1)",
    },

    "& .inputField:focus": {
      outline: "none",
      border: "1px solid #24eef41c",
    },

    "& .inputPhoneNumberDropDown:focus": {
      outline: "none",
      border: "1px solid #24eef41c",
    },

    "& .inputPhoneNumberDropDown": {
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      paddingLeft: "14px",
      borderRadius: "100px",
      border: "1px solid rgba(28, 150, 154, 0.11)",
      width: "88px",
      color: "white",
      appearance: "none",
      padding: "10px 16px 10px 16px",
      borderBottom: "none",
      "&.MuiInput-underline::before": {
        borderBottom: "none",
      },
      "&.MuiInput-underline::after": {
        borderBottom: "none",
      },
      "&.MuiInput-underline:hover:not(.Mui-disabled)::before": {
        borderBottom: "none",
      }
    },
    "& .inputFieldPhone": {
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      padding: "10px 16px 10px 16px",
      borderRadius: "100px",
      border: "1px solid rgba(28, 150, 154, 0.11)",
      width: "247px",
      height: "48px",
      marginLeft: "10px",
      color: "white",
      borderBottom: "none",
      fontWeight: 400,
      fontSize: "16px",
    },
    "& .inputFieldPhone:focus": {
      border: "none",
      outline: "none"
    },
    "& .loginInputBoxContainer": {
      marginTop: "15px",
    },
    "& .inputLabel": {
      color: "white",
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .loginBtn": {
      background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
      width: "100%",
      height: "48px",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.3)",
      padding: "12px, 16px, 12px, 16px",
      borderRadius: "100px",
      color: "rgba(255, 255, 255, 0.8)",
      border: "none",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      cursor: "pointer",
      "&:disabled": {
        cursor: "inherit",
        opacity: 0.3
      }
    },
    "& .loginBtn:focus": {
      border: "none"
    },
    "& .tncCheckbox": {
      width: "20px",
      height: "20px",
      borderRadius: "14px",
    },
    "& .termsCheckedImg": {
      width: "20px",
      height: "20px",
    },
    "& .termsAndConditionBox": {
      display: "flex",
      marginTop: "20px",
    },
    "& .txtNormalPalette": {
      marginLeft: "7px",
      color: "rgba(255, 255, 255, 0.8)",
      fontSize: "14px",
    },
    "& .txtPallete": {
      color: "rgba(255, 107, 118, 1)",
    },
    "& .textNavigate": {
      color: "rgba(255, 107, 118, 1)",
      cursor: "pointer",
    },
    "& .alreadyAccountTxt": {
      display: "flex",
      justifyContent: "center",
      width: "343px",
      marginTop: "20px",
    },
    [`@media screen and (max-width: 960px)`]: {
      "& .left":{
        display: "none"
      },
      "& .right":{
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
      },
      "& .inputFieldPhone":{
        width: "100%"
      },
      "& .loginRightArea":{
        width: "90vw"
      },
      "& .loginInputBoxContainer":{
        width: "100%"
      },
      "& .childContainer":{
        width: "100%"
      },
      "& .alreadyAccountTxt":{
        width: "100%",
        textAlign: "center"
      }
    },
    [`@media screen and (max-width: 480px)`]: {
      "& .childContainer":{
        width: "90vw"
      },
    },
  }
});

const CustomDropDown = styled("div")({
  position: "relative",
  cursor: "pointer"
});

const DropDownHeader = styled("div")({
  padding: "10px",
  display: "flex",
  justifyContent: "flex-end",
  border: "none",
  borderRadius: "40px",
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  height: "30px",
  width: "70px",
  color: "white",
  fontWeight: 400,
  fontSize: "16px",
  "&.isActive":{
  border: "1px solid red",
  },
  "&:hover": {
      backgroundColor: "#F0F0F0"
  },
  "& .selectDropdown":{
    color: "white",
  }
});

const SelectedCountry = styled("div")({
  display: "flex",
});

const DropDownOptions = styled("div")({
  left: 0,
  width: "200px",
  border: "1px solid linear-gradient(0deg, rgba(28, 150, 154, 0.11) 0%, rgba(25, 181, 185, 0.27) 100%)",
  borderTop: "none",
  borderRadius: "8px",
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  position: "absolute",
  zIndex: 1,
  height: "140px",
  overflow: "auto",
  color: "white",
});

const OptionsValue = styled("div")({
  padding: "10px",
  display: "flex",
  "&:hover": {
    backgroundColor: "linear-gradient(180deg, #23404B 0%, #19282F 100%)"
  },
  "& .optionValuesList":{
    display: "flex"
  },
  "& .countryFlagData":{
    widtrh: "20px",
    height: "20px"
  }
});

const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    padding: 0, // Set padding to 0
  },
  "& .MuiPaper-root":{
    background: "transparent"
  }
});

const StyledSearchCountryInput = styled("div")({
  boxSizing:"border-box",
  padding:"0.5rem 0.3rem",
  "& input":{
    outline:"none",
    color:"white",
    padding:"0.1rem 0.5rem",
    fontSize:"0.875rem",
    background:"transparent",
    border:"1px solid #19B5B945",
    width:"100%",
    borderRadius:"0.5rem", 
  }
})
// Customizable Area End