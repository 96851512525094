import React from "react";
import { styled } from "@material-ui/styles";
import { Button } from "../../../../components/src";
import { AddIcon } from "../../../dashboard/src/assets";
import { Box, Button as MaterialButton } from "@material-ui/core";
interface Props {
    redirectTo: (endpoint: string, params?:{[key:string]:string | number})=> void;
    handleOpenPendingRequests: () => void;
    pendingRequestCount: number;
}


const GroupHeader: React.FC<Props> = (props: Props) => {
    const { redirectTo, handleOpenPendingRequests, pendingRequestCount } = props;

    return (
        <StyledGroupHeader className="GroupHeader_StyledGroupHeader">
            <p>Groups</p>
            <Box className="group-header-btns-box">
                <MaterialButton id="group-pending-request" className="group-pending-request-btn" onClick={handleOpenPendingRequests}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <p>Pending Request</p>
                        <Box className="pending-request-count">{pendingRequestCount}</Box>
                    </Box>
                </MaterialButton>
                <Button
                    text="Add New Group"
                    icon={AddIcon}
                    onClick={() => redirectTo("AddGroup")}
                />
            </Box>
        </StyledGroupHeader>
    )
}

const StyledGroupHeader = styled("div")({
    "&.GroupHeader_StyledGroupHeader": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& button": {
            "& p": {
                fontSize: "1rem",
                fontWeight: 500
            },
            "&.group-pending-request-btn": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "3rem",
                padding: "0.75rem",
                gap: "0.25rem",
                borderRadius: "62.5rem",
                background: "transparent",
                outline: "none",
                cursor: "pointer",
                minWidth: "9.125rem",
                marginRight: "15px",
                border: "1px solid rgba(247, 83, 103, 1)",
                "& p": {
                    transform: "translateY(1px)",
                    color: "rgba(247, 83, 103, 1)",
                    textTransform: "capitalize",
                },
                "& .pending-request-count": {
                    width: "17px",
                    height: "17px",
                    fontSize: "12px",
                    borderRadius: "50%",
                    color: "rgba(255, 255, 255, 1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(247, 83, 103, 1)",
                    marginLeft: "15px"
                },
            },
        },
        "& > p": {
            fontSize: "1.25rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)"
        },
        "& .group-header-btns-box": {
            display: "flex",
            alignItems: "center",
            [`@media screen and (max-width: 600px)`]: {
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px"
            },
        },
        [`@media screen and (max-width: 600px)`]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    }
})

export default GroupHeader;