// Customizable Area Start
import React, { createRef } from "react";
import {
  Typography,
  styled,
  Box,
  Grid,
  Tooltip
} from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RemindersController, {
  Props
} from "./RemindersController";
import ReminderHeader from "./components/ReminderHeader.web";
import { CustomDateField } from "../../scheduling/src/components/CustomDateField";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import ReminderCard from "./components/ReminderCard.web";
import { IReminderItem } from "./types";
import { crossIcon, markAsCompleteIcon, selectAllIcon } from "./assets";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import ReminderDetailsPopup from "./components/ReminderDetailsPopup.web";
import ReminderDateHeader from "./components/ReminderDateHeader.web";
// Customizable Area End

export default class Reminder extends RemindersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderDayContents = (dayValue: number, dateValue: Date) => {
    const selected = this.IsEqualDate(dateValue, this.state.currentDate) ? "selected" : "";
    const past = this.isPastDate(dateValue) ? "past" : "";
    const today = this.IsEqualDate(dateValue, new Date()) ? "today" : "";
    return (
      <StyledCustomDay className={`${selected} ${this.dayClassName(dateValue)} ${past} ${today}`}>
        {dayValue}
        <span></span>
      </StyledCustomDay>
    )
  };

  Toolbar = () => {
    const { currentDate, isReminderDatePickerOpen } = this.state;
    return (
      <StyledReminderCalendarToolbar className="toolbar">
        <div className="left">
          <div className="date-picker" id="date-picker">
            <DatePicker
              open={isReminderDatePickerOpen}
              selected={currentDate}
              onChange={this.goToSelectedDate}
              customInput={
                <CustomDateField currentDate={currentDate} />
              }
              renderDayContents={this.renderDayContents}
              onCalendarOpen={() => this.onDatePickerChange(currentDate)}
              onMonthChange={this.onDatePickerChange}
              onSelect={this.onDateSelectFromDatePicker}
              onClickOutside={this.clickedOutside}
              onInputClick={this.toggleDatePicker}
            />
          </div>
        </div>
      </StyledReminderCalendarToolbar>
    )
  };

  WeekDay = () => {
    const { daysOfWeek } = this.state;
    return (
      <StyledWeekDay>
        <Box id="goto-prev-week" onClick={this.handleGoToPreviousWeek} className="horizontal-scroll"><ChevronLeftIcon fontSize="large" /></Box>
        {daysOfWeek.map((day) =>
          <Box key={day.date} onClick={() => this.handleOnClickDateFromWeekDay(day.fullDate)} className={`weekday-box ${day.isSelected ? "selected" : ""}`}>
            <Box className={!day.isCurrentMonth ? "past-weekday-date" : "weekday-date"}>
              <Typography component={"p"}>
                {day.date}
              </Typography>
            </Box>
            <Box className="weekday-day">
              <Typography component={"p"}>
                {day.day}
              </Typography>
            </Box>
          </Box>
        )}
        <Box id="goto-next-week" onClick={() => this.handleGoToNextWeek()} className="horizontal-scroll"><ChevronRightIcon fontSize="large" /></Box>
      </StyledWeekDay>
    )
  };

  TimerLine = () => {
    return (
      <>
        <Box className="time-dot"></Box>
        <Box className="time-line"></Box>
        <Box className="time-dot"></Box>
      </>
    )
  }

  ReminderCardContainer = () => {
    const { reminders, lastLapsedFalseElement, isRemindersBeingFetched } = this.state;
    return (
      <StyledReminderCardContainer>
        <Grid ref={this.parentRef} style={{
          overflow: !isRemindersBeingFetched ? "scroll" : "none"
        }} className="reminder-card-main-grid" container direction="row">
          {reminders.length > 0 ? reminders.map((reminder, index) => {
            if (!this.childRefs[index]) {
              this.childRefs[index] = createRef<HTMLDivElement>();
            }
            return (
              <Grid key={`${reminder.date}-${index}`} item xs={12}>
                <Grid container direction="row">
                  <Grid data-test-id="reminder-date" id={`${reminder.date}-${reminder.year}`} ref={this.childRefs[index]} item xs={12}>
                    <ReminderDateHeader reminder={reminder} handleChangeWeek={(date, year) => this.handleChangeWeek(date, year)} />
                  </Grid>
                  {reminder.reminders?.map((reminderItem: IReminderItem, itemIndex) =>
                    <Grid style={{ opacity: this.isReminderCardDisabled(reminderItem) ? 0.6 : 1, position: this.getPositionOfCard(reminderItem) }} key={reminderItem.id} className="reminder-card" item xs={12}>
                      <ReminderCard
                        isLastReminderElement={(itemIndex === reminder.reminders.length - 1) && (index === reminders.length - 1)}
                        onSelectReminder={(reminderId: string) => this.handleSelectReminder(reminderId)}
                        reminder={reminderItem}
                        onRedirect={this.redirectTo}
                        onClickDeleteIcon={this.openReminderDeleteModal}
                        onOpenReminderDetails={this.handleOpenReminderDetails}
                        handleFetchNextPageReminders={() => this.handleChangePageNumber()}
                      />
                      {reminderItem.id === lastLapsedFalseElement?.id &&
                        <Box className="timerline" top={itemIndex > 0 ? "-24px" : "-55px"} position={"absolute"} width={"100%"} display={"flex"} alignItems={"center"}>
                          {this.TimerLine()}
                        </Box>
                      }
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )
          }
          ) :
            <CustomNoDataComponent useCommonEmptyImg={false} titleMsg="No Reminders" subTitleMsg="No Reminders.Stay tuned for updates." />
          }
          {(reminders.length > 0 && !lastLapsedFalseElement) && 
            <Box className="timerline" bottom={"16px"} position={"absolute"} width={"100%"} display={"flex"} alignItems={"center"}>
            {this.TimerLine()}
            </Box>
          }
        </Grid>
      </StyledReminderCardContainer>
    )
  }

  Chip = () => {
    return (
      <StyledReminderSelectedChip>
        <img data-test-id={"unselect-all-reminders"} src={crossIcon} alt="cross.svg" onClick={() => this.handleUnselectAllReminders()} />
        <p>{`${this.handleGetNumberOfSelectedReminders()} selected`}</p>
      </StyledReminderSelectedChip>
    )
  }

  MarkAsCompleteComponent = () => {
    return (
      <StyleMarkAsComplete>
        <Box>
          {this.Chip()}
        </Box>
        <Box className="mark-icons">
          <Tooltip title="Select All" placement="top">
            <img id="select-all" onClick={() => this.handleSelectAllReminders()} src={selectAllIcon} alt="select-all" />
          </Tooltip>
          <Tooltip title="Mark As Complete" placement="top">
            <img id="mark-as-complete" onClick={() => this.markRemindersAsComplete()} src={markAsCompleteIcon} alt="mark" />
          </Tooltip>
        </Box>
      </StyleMarkAsComplete>
    )
  }

  ReminderContainer = () => {
    const { deleteReminderConfirmPopUpData, selectedReminderDetails } = this.state;
    return (
      <StyledReminderContainer>
        <ReminderHeader showCountOfReminders toDoCount={this.getCompletedTodoData().todo} completedCount={this.getCompletedTodoData().completed} redirectTo={this.redirectTo} />
        {this.isAnyReminderSelected() && this.MarkAsCompleteComponent()}
        <div className="reminder-main-body">
          {!this.isAnyReminderSelected() &&
            <Box className="reminder-date-week-picker">
              <div className="datepicker">
                {this.Toolbar()}
              </div>
              {this.WeekDay()}
            </Box>
          }
          <div className="reminder-cards-container">
            {this.ReminderCardContainer()}
          </div>
        </div>
        <ConfirmationPopUp
          open={Boolean(deleteReminderConfirmPopUpData)}
          header={deleteReminderConfirmPopUpData?.header as string}
          message={deleteReminderConfirmPopUpData?.message as string}
          onAccept={this.onAcceptDeleteConfirmation}
          onReject={this.onCloseDeleteConfirmationModal}
          onClose={this.onCloseDeleteConfirmationModal}
        />
        <ReminderDetailsPopup
          reminder={selectedReminderDetails}
          onCloseUserListModal={this.onCloseReminderDetailModal}
          onDelete={this.openReminderDeleteModal}
          onEdit={(reminderId: string) => this.redirectTo("EditReminder", { id: reminderId })}
          onMarkAsComplete={(reminderId: string) => this.markRemindersAsComplete(reminderId)}
        />
      </StyledReminderContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu navigation={this.props.navigation} id={this.props.id} children={this.ReminderContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledReminderContainer = styled("div")({
  padding: "1rem 1rem 0rem 1rem",
  position: "relative",
  "& .reminder-main-body": {
    padding: "32px 144px",
    [`@media screen and (max-width: 850px)`]: {
      padding: "0px",
    },
    "& .reminder-date-week-picker": {
      position: "relative",
    },
  },
  "& .react-datepicker__triangle": {
    display: "none"
  },
  "& .react-datepicker": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1.21px solid #19B5B945",
    borderRadius: "1rem",
    overflow: "hidden"
  },
  "& .react-datepicker__header": {
    // background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    background: "transparent",
    border: "none",
    paddingBottom: 0,
    paddingTop: "1rem"
  },
  "& .react-datepicker__navigation--next": {
    right: "15%",
    top: "2.8%"
  },
  "& .react-datepicker__navigation--previous": {
    left: "15%",
    top: "2.8%"
  },
  "& .react-datepicker__current-month": {
    color: "#ffffff",
    fontSize: "1rem",
    fontWeight: "500",
    marginBottom: "0.5rem"
  },
  "& .react-datepicker__day-name": {
    color: "#ffffff",
    fontSize: "0.875rem",
    fontWeight: "500"
  },
  "& .react-datepicker-popper": {
    zIndex: 2,
    marginLeft: "-6.25rem"
  },
  '& .react-datepicker-popper[data-placement^="bottom"]': {
    padding: "0.5rem"
  },
  "& .react-datepicker__day": {
    "&:hover": {
      background: "transparent",
      borderRadius: "100%"
    }
  },
  "& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected": {
    background: "transparent",
    // borderRadius: "100%"
  },
  "& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name": {
    margin: "0.5rem"
  },
  "& .reminder-cards-container": {
    padding: "15px 0px",
  },
})

const StyledReminderCalendarToolbar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  "& button": {
    border: "none",
    outline: "none",
    background: "transparent",
    cursor: "pointer"
  },
  "& .left": {
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
    "& .today-btn": {
      width: "5.1875rem",
      height: "2.25rem",
      border: "1px solid #F75367",
      borderRadius: "62.5rem",
      color: "#F75367",
      fontSize: "1rem",
      fontWeight: 500
    },
    "& .navigation-btn-container": {
      display: "flex",
      width: "4.0625rem",
      height: "2.25rem",
      borderRadius: "0.3125rem",
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      alignItems: "center",
      padding: "0rem 0.5rem",
      justifyContent: "space-between",
      "& > div": {
        height: "60%",
        width: "1px",
        background: "#FFFFFF2E",
      }
    },
    "& .selected-date": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      color: "#FFFFFF",
      fontSize: "1.25rem",
      fontWeight: 500,
      cursor: "pointer",
      [`@media screen and (max-width: 950px)`]: {
        fontSize: "1rem"
      },
    }
  },
  "& .right": {
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
    "& .view-container": {
      width: "8.8125rem",
      height: "2.25rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      border: "1px solid #19B5B945",
      borderRadius: "3.125rem",
      padding: "0rem 0.25rem",
      "& button": {
        width: "4rem",
        height: "1.75rem",
        color: "#FFFFFF",
        opacity: "50%",
        fontSize: "0.875rem",
        padding: "0rem 0.25rem",
        borderRadius: "3.125rem",
        transition: "all 0.3s ease-in-out",
        "&.active": {
          background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
          fontWeight: 500,
          opacity: 1
        }
      }
    },
    "& .filter-btn": {
      height: "2.25rem",
      width: "5.9375rem",
      border: "1px solid #F75367",
      color: "#F75367",
      fontSize: "1rem",
      fontWeight: "500",
      borderRadius: "62.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.3rem"
    }
  },
  [`@media screen and (max-width: 950px)`]: {
    flexDirection: "column",
    gap: "1.5rem"
  },
})

const StyledCustomDay = styled("div")({
  position: "relative",
  color: "#FFFFFFCC",
  fontWeight: 400,
  fontSize: "0.875rem",
  borderRadius: "100%",
  width: "2rem",
  height: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "background-color 0.3s ease-in-out",
  "&.reminder-date span": {
    position: "absolute",
    width: "0.25rem",
    height: "0.25rem",
    backgroundColor: "#F5686F",
    right: 3,
    top: 6,
    borderRadius: "100%",
  },
  "&.today": {
    border: "1px solid #F5686F",
  },
  "&.reminder-date.past span": {
    backgroundColor: "#808080"
  },
  "&.selected.reminder-date span, &.today.reminder-date span": {
    display: "none"
  },
  "&.selected": {
    backgroundImage: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
    backgroundColor: "#F5686F",
  },
  "&:hover": {
    fontWeight: 500,
    backgroundImage: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
    backgroundColor: "#F5686F",
    borderRadius: "100%",
    color: "#ffffff",
    "& span": {
      display: "none"
    }
  }
})

const StyledWeekDay = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  overflowX: "auto",
  marginBottom: "10px",
  "& .horizontal-scroll": {
    color: "#ffffff",
    cursor: "pointer"
  },
  "& .selected": {
    border: "1px solid #F47273",
  },
  "& .weekday-box": {
    padding: "20px 10px",
    borderRadius: "16px",
    cursor: "pointer",
  },
  "& .weekday-date": {
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
    color: "#FFFFFF"
  },
  "& .past-weekday-date": {
    color: "rgba(255, 255, 255, 0.5)",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "center",
  },
  "& .weekday-day": {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.7)"
  }
})

const StyledReminderCardContainer = styled("div")({
  color: "#FFFFFF",
  position: "relative",
  "& .reminder-card-main-grid": {
    maxHeight: "65%",
    position: "relative",
  },
  "& .reminder-date": {
    fontSize: "16px"
  },
  "& .reminder-card": {
    marginBottom: "30px"
  },
  "& .timerline": {
    // width: "112%",
    // left: "-6%",
    [`@media screen and (max-width: 1420px)`]: {
      width: "100%",
      left: "0px",
    },
  },
  "& .time-dot": {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: "rgba(247, 83, 103, 1)"
  },
  "& .time-line": {
    width: "100%",
    height: "1px",
    background: "rgba(247, 83, 103, 1)"
  }
})

const StyleMarkAsComplete = styled("div")({
  backgroundColor: "rgba(35, 64, 75, 1)",
  padding: "16px 15px",
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "15px",
  "& .mark-icons": {
    "& img": {
      cursor: "pointer",
    },
    "& img:first-child": {
      marginRight: "8px"
    }
  }
})

const StyledReminderSelectedChip = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "#F37976",
  padding:"5px 10px",
  border: "1px solid #F37976",
  borderRadius: "16px",
  justifyContent:"center",
  "& p": {
    margin: 0,
    fontSize: "14px",
    whiteSpace: "nowrap"
  },
  "& img": {
    cursor: "pointer",
    marginRight: "8px"
  }
})

// Customizable Area End
