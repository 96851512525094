import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
const { HelperFunctions: helper } = require("./HelperFunctions");

interface Props {
    isTimerOn?: boolean;
    onTimerEnd?: () => void;
    onTimerStart?: () => void;
    timerDuration?: number;
    storageName?: string;
    style?: React.CSSProperties
}


const Timer: React.FC<Props> = (props: Props) => {
    const { isTimerOn, onTimerEnd, timerDuration = 5 * 60 * 1000, style } = props;
    const [lockoutEnd, setLockoutEnd] = useState<number | null>(null);
    const [timer, setTimer] = useState('');


    const startTimer = async () => {
        const currentTime = Date.now();
        const newLockoutEnd = currentTime + timerDuration;
        await helper.setStorageData('lockoutEnd', newLockoutEnd.toString());
        setLockoutEnd(newLockoutEnd);
        setTimer(`${Math.floor(timerDuration / (1000 * 60))}:00`);
    };

    const getFormattedTime = (time: number) => {
        const minutes = Math.floor(time / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        return `${minutes >= 10 ? minutes : "0" + minutes}:${
          seconds >= 10 ? seconds : "0" + seconds
        }`;
      };

    useEffect(() => {
        (async () => {
            // Initialize sign-in attempts and lockout state from localStorage
            const savedLockoutEnd = await helper.getStorageData('lockoutEnd');
            const currentTime = Date.now();
            if (savedLockoutEnd && currentTime < parseInt(savedLockoutEnd, 10)) {
                setLockoutEnd(parseInt(savedLockoutEnd, 10));
            } else {
                await helper.removeStorageData('lockoutEnd');
                startTimer();
            }
        })()
    }, []);

    useEffect(() => {
        // Update the timer every second if lockoutEnd is set
        if (lockoutEnd && isTimerOn) {
            const interval = setInterval(async () => {
                const currentTime = Date.now();
                const remainingTime = lockoutEnd - currentTime;

                if (remainingTime <= 0) {
                    clearInterval(interval);
                    setTimer('');
                    setLockoutEnd(null);
                    await helper.removeStorageData('lockoutEnd');
                    onTimerEnd?.();
                } else {
                    setTimer(getFormattedTime(remainingTime));
                    await helper.setStorageData('lockoutEnd', remainingTime.toString());
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [lockoutEnd, isTimerOn]);
    return (
        <>
            {
                timer && (
                    <StyledTimer style={style} className="Custom_Timer">
                        <p>{timer}</p>
                    </StyledTimer>
                )
            }
        </>
    )
}

const StyledTimer = styled("div")({
    "&.Custom_Timer": {
        fontSize: "0.75rem",
        fontWeight: 400,
        color: "rgb(255, 255, 255)"
    }
})

export default Timer;